import React, { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animation from "./animation.json";
function BookingForm() {
  const [queryParamsFetched, setQueryParamsFetched] = useState(false);

  useEffect(() => {
    // http://localhost:3000?service=Regular&bedrooms=2%20Bedrooms&bathrooms=2%20Bathroom&kitchens=2%20Kitchens&dirtiness=Pretty%20Dirty&additionalServices=Laundry&day=24-03-2024&timeOfDay=Morning&howOften=Weekly&firstName=Tom&lastName=Galland&phone=%2B61%20450%20750%20586&email=james%40james.com&locationAddress=130%20Wellington%20Street&locationAddress2=_____&city=Waterloo&state=NSW&company=_____&postcode=2017&comments=Comments%20HERE&price=288&discount=400
    // https://book.medford.com.au/?service=Regular&bedrooms=1%20Bedroom&bathrooms=1%20Bathroom&kitchens=1%20Kitchen&dirtiness=Slightly%20Dirty&additionalServices=General%20Organisation&day=28%2F04%2F2024&timeOfDay=Morning&howOften=One%20Time&firstName=Tom&lastName=Galland&phone=%2B61%20450%20750%20586&email=candice@seogrowth.io&locationAddress=130%20wellington%20street&locationAddress2=_____&city=Waterloo&state=NSW&company=_____&postcode=2017&comments=_____&price=220&discount=0
    if (!queryParamsFetched) {
      getAllQueryParams();
      setQueryParamsFetched(true);
    }
  }, [queryParamsFetched]);

  async function getAllQueryParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Check if there are no parameters
    if (urlSearchParams.toString() === "") {
      // Do nothing if there are no parameters
      return;
    }

    let queryString = "";
    for (const [key, value] of urlSearchParams.entries()) {
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(decodeURIComponent(value))}&`;
    }
    // Remove the trailing '&' if it exists
    if (queryString.endsWith("&")) {
      queryString = queryString.slice(0, -1);
    }


    // window.location.href = `http://127.0.0.1:5001/medfordcleaningform/us-central1/saveBookingDataQuery?${queryString}`;
    window.location.href = `https://us-central1-medfordcleaningform.cloudfunctions.net/saveBookingDataQuery?${queryString}`;

    return queryString;
}


  return (
    <Container
      maxWidth="md"
      style={{
        backgroundColor: "white",
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex", // Make the container a flex container
        alignItems: "center", // Center items vertically
        justifyContent: "center", // Center items horizontally
        textAlign: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          height: "30vh",
          width: "20vw",
          borderRadius: "30px",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animation,
          }}
          // height={400}
          // width={400} // Set the width of your animation
        />
        <Typography color="#097AFD" sx={{ mt: 3 }} variant="h6">
          Confirming Booking....
        </Typography>
      </div>
    </Container>
  );
}

export default BookingForm;
